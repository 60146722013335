import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Col, Container, Img, Row } from 'ui/layout';
import { fontMagra, Text } from 'ui/typography';
import ill1 from 'assets/credo/ill11.webp';
import ill2 from 'assets/credo/ill21.webp';
import ill3 from 'assets/credo/ill3.webp';
import icon from 'assets/credo/icon.svg';
import arrow_left from 'assets/credo/arrow_left.svg';
import { BREAKPOINTS } from '../../../../utils';

const SLIDES = [ill3, ill1, ill2];
let init = false;

export const Credo = (props) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const ref = useRef(null);
  // const navigationPrevRef = useRef(null);
  // const navigationNextRef = useRef(null);

  useEffect(() => {
    if (!init && ref.current) {
      init = true;
      setTimeout(() => {
        ref.current.swiper.slideToLoop(1, 0, true);
      }, 1000);
    }
  }, [ref]);

  return (
    <Container {...props} paddingBottom={24}>
      <Row gap={28} lgGap={18} smdGap={8} justifyContent={'center'} nowrap>
        <Col auto lgMarginLeft={14} smdMarginLeft={1}>
          <Icon src={icon} alt={''} />
        </Col>
        <Col auto>
          <Container
            maxWidth={540}
            lgMaxWidth={378}
            marginTop={23}
            lgMarginTop={-4}
            smdMarginTop={9}
          >
            <Title>
              Ориентир на технологии и инновации — кредо нашей компании!
            </Title>
          </Container>
        </Col>
      </Row>

      <Container marginTop={89} lgMarginTop={104} smdMarginTop={32}>
        <StyledSwiper
          spaceBetween={10}
          initialSlide={currentSlide}
          centeredSlides
          modules={[Navigation]}
          loop
          slidesPerView={'auto'}
          slideToClickedSlide
          onSlideChange={(e) => setCurrentSlide(e.realIndex)}
          ref={ref}
          breakpoints={{
            [BREAKPOINTS.xl]: {
              spaceBetween: 60
            },
            [BREAKPOINTS.lg]: {
              spaceBetween: 40
            },
            [BREAKPOINTS.smd]: {
              spaceBetween: 21
            }
          }}
          onInit={(swiper) => {
            swiper.slideToLoop(1, 0, true);
          }}
        >
          {SLIDES.map((item, key) => {
            const active = currentSlide === key;
            return (
              <SwiperSlide key={key}>
                <Slide src={item} active={active} />
                {active && <ArrowLeft src={arrow_left} />}
                {active && <ArrowRight src={arrow_left} />}
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      </Container>

      <CounterContainer marginTop={20} lgMarginTop={10} textCenter>
        <Text inline>{currentSlide ? currentSlide : SLIDES.length} / </Text>
        <Text color={'#909090'} inline>
          {SLIDES.length}
        </Text>
      </CounterContainer>
    </Container>
  );
};

const Icon = styled(Img)`
  width: 79px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 57px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 27px;
  }
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  ${fontMagra}
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    max-width: 226px;
  }
`;
const Slide = styled.img`
  height: 700px;
  width: 100%;
  max-width: 1200px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.1;
  transition: 0.4s;
  cursor: pointer;

  ${(p) =>
    p.active
      ? `
    opacity: 1;
    cursor: default;
  `
      : ''}
  @media (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 900px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 339px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 260px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 180px;
  }
`;
const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    width: auto;
    max-width: 80%;
  }
`;
const ArrowLeft = styled.img`
  width: 46px;
  position: absolute;
  left: -95px;
  top: 50%;
  transform: translate(-100%, -50%);
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    left: -60px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 22px;
    left: -40px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 14px;
    left: -15px;
  }
`;
const ArrowRight = styled(ArrowLeft)`
  left: unset;
  right: -95px;
  transform: translate(100%, -50%) rotate(180deg);
  @media (max-width: ${BREAKPOINTS.xl}px) {
    right: -60px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    right: -40px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    right: -15px;
  }
`;
const CounterContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
